/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* Bar Rating */
@import '~@arbitral/ngx-bar-rating/themes/br-default-theme';
@import '~@arbitral/ngx-bar-rating/themes/br-horizontal-theme';

/* Primeng */
@import 'primeng/resources/themes/saga-orange/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import 'quill-emoji/dist/quill-emoji.css';
@import 'quill-mention/dist/quill.mention.min.css';

@import '@algolia/autocomplete-theme-classic';
