/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

:root {
  $primaryColor: #f59e09;

  @media (max-width: theme('screens.sm')) {
    .cdk-overlay-pane.dialog-pane {
      width: 100% !important;
      max-width: 100vw !important;
    }

    .mat-dialog-container {
      border-radius: 0 !important;

      form {
        max-height: calc(100vh - 64px) !important;
      }
    }
  }

  .tutorial-content a {
    color: #548dff;
    font-weight: bold;
    text-decoration: #548dff underline;
  }

  .mat-tab-body-content {
    overflow: visible!important;
  }

  .mat-tab-body.mat-tab-body-active {
    overflow-y: visible!important;
    overflow-x: visible!important;
  }

  .mat-tab-body-wrapper {
    overflow: visible!important;
  }

  .mat-snack-bar-container {
    &.success {
      @apply bg-green-500;
      @apply text-gray-300;
    }

    &.error {
      @apply bg-red-400;
      @apply text-gray-300;
    }

    &.warning {
      @apply bg-amber-500;
      @apply text-gray-300;
    }
  }

  .ngx-gauge-meter {
    .reading-label {
      right: 0;
      top: 4px;
    }
  }

  .progress-spinner-overlay {
    position: absolute !important;
    top: 45vh !important;
  }

  debate-rating-form,
  debate-comment-form {
    .mat-form-field.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex {
      border-color: rgba(203, 213, 225, var(--tw-border-opacity)) !important;

      textarea {
        &::-webkit-input-placeholder {
          color: rgba(var(--fuse-text-secondary-rgb), 0.4) !important;
        }

        &::-moz-placeholder {
          color: rgba(var(--fuse-text-secondary-rgb), 0.4) !important;
        }

        &:-ms-input-placeholder {
          color: rgba(var(--fuse-text-secondary-rgb), 0.4) !important;
        }

        &:-moz-placeholder {
          color: rgba(var(--fuse-text-secondary-rgb), 0.4) !important;
        }

        &::placeholder {
          color: rgba(var(--fuse-text-secondary-rgb), 0.4) !important;
        }

        caret-color: var(--fuse-primary) !important;
      }
    }
  }

  .popover {
    margin: 8px auto;
    max-width: 400px !important;
    // margin-left: 108px;

    .popover-arrow {
      display: none;
    }
  }

  .debate-button {
    background-color: $primaryColor !important;
    color: #ffffff !important;
  }

  .p-toast-message-custom .p-toast-message-content {
    background-color: scale-color($primaryColor, $lightness: 30%);
    border: solid scale-color($primaryColor, $lightness: -20%);
    border-width: 0 0 0 6px;
    color: #4f4e4e;

    .p-toast-icon-close {
      color: #4f4e4e;
    }
  }

  .highlight-scroll-to {
    border-style: dashed;
    border-color: $primaryColor;
    border-width: 3px;
    border-radius: 3px;
  }

  .highlight-scroll-to-alt {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 3px solid $primaryColor;
      animation: clippath 1s 1 linear;
    }
  }

  @keyframes clippath {
    0%,
    100% {
      clip-path: inset(0 0 95% 0);
    }
    25% {
      clip-path: inset(0 95% 0 0);
    }
    50% {
      clip-path: inset(95% 0 0 0);
    }
    75% {
      clip-path: inset(0 0 0 95%);
    }
  }

  .error-pulse {
    display: block;

    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
  }

  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }

  .error-pulse-alt {
    display: block;

    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red-alt 2s infinite;
  }

  @keyframes pulse-red-alt {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }

  .news-paywall {
    .ribbon {
      position: absolute;
      right: -5px;
      top: -5px;
      z-index: 1;
      overflow: hidden;
      width: 75px;
      height: 75px;
      text-align: right;
      opacity: 0.5;

      span {
        font-size: 10px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 100px;
        display: block;
        background: #79a70a;
        background: linear-gradient(#fbbf24 0%, #f59e0b 100%);
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 19px;
        right: -21px;

        &::before {
          content: '';
          position: absolute;
          left: 0px;
          top: 100%;
          z-index: -1;
          border-left: 3px solid $primaryColor;
          border-right: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-top: 3px solid $primaryColor;
        }

        &::after {
          content: '';
          position: absolute;
          right: 0px;
          top: 100%;
          z-index: -1;
          border-left: 3px solid transparent;
          border-right: 3px solid $primaryColor;
          border-bottom: 3px solid transparent;
          border-top: 3px solid $primaryColor;
        }
      }

      &.large {
        width: 125px;
        height: 125px;

        span {
          top: 32px;
          right: -35px;
          font-size: 12px;
          width: 175px;
          line-height: 24px;
        }
      }

      &.cover-on-image {
        opacity: 0.9;
      }
    }
  }

  .p-editor-container {
    .p-editor-toolbar {
      button {
        width: 32px;
        height: 28px;
      }
    }

    .p-editor-content {
      display: grid;
      border: 1px solid #dee2e6 !important;
      font-size: 16px;
      font-weight: 200;

      &:hover {
        border: 1px solid $primaryColor !important;
      }
    }

    #emoji-palette {
      #tab-panel .bem.ap {
        margin: 4px 2px !important;
      }
    }
  }
}
